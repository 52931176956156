<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="container-sm">
      <br><br><br><br><br>
      <router-link
        :to="{ name: 'home' }"
        style="color: #000"
      >
        <div class="d-flex align-items-center">
          <div class="box-back text-center mr-1">
            <i class="fas fa-long-arrow-left" />
          </div>
          <!-- <h5 class="text-center font-weight-bolder m-0">

          </h5> -->
        </div>
      </router-link>

      <div class="card p-1 mt-1">
        <div class="text-center header-dark">
          <h5 class="font-weight-bolder m-0">
            โบนัสยอดเสียเพื่อนรายเดือน 5 %
          </h5>
        </div>

        <div class="invite_color">
          <div class="d-flex justify-content-between">
            <div>
              <span class="text-center highlight">เงื่อนไขการรับโบนัส</span>
              <p>
                - {{ prolist ? prolist.detail : '' }} <br>
                - สามารถรับได้ตั้งแต่วันที่ 1 - 5 ของทุกเดือน <br>
              </p>
            </div>

          <!-- <img
            src="/game-list/qr-code.png"
            height="80px"
            style="border-radius: 10px"
            alt=""
          > -->
          <!-- <vue-qrcode
            type="image/jpeg"
            height="90"
            :value="`https://superrich.ufabet01.co/register-ufa?ref=${User.invite_code}`"
          /> -->

          </div>
        </div>
        <div>
          <!-- <span>ลิงก์ชวนเพื่อน</span> <br>
          <small>{{ agent.site }}/register?ref={{ User.invite_code }}</small>
          <br> -->
          <button
            class="btns"
            @click="ReceivePro10()"
          >
            <i class="fad fa-donate" />
            รับโบนัส
          </button>
        </div>
      </div>
      <div class="text-left text-head-hisotry mt-2">
        รายชื่อเพื่อนที่แนะนำ
      </div>

      <div
        class="
        w-100
        border-solid
        rounded-lg
        text-color
        bg-table
        border-10
        table-responsive
      "
      >
        <table
          role="table"
          aria-busy="false"
          aria-colcount="3"
          class="table b-table table-sm"
        >
          <thead role="rowgroup">
            <tr role="row">
              <th
                role="columnheader"
                scope="col"
                aria-colindex="1"
                class="small text-left pl-1"
              >
                <div>ชื่อ - นามสกุล</div>
              </th>
              <!-- <th
                role="columnheader"
                scope="col"
                aria-colindex="2"
                class="small text-center pr-1"
              >
                <div>ฝาก</div>
              </th> -->
              <th
                role="columnheader"
                scope="col"
                aria-colindex="2"
                class="small text-center pr-1"
              >
                <div>wimlose</div>
              </th>
              <th
                role="columnheader"
                scope="col"
                aria-colindex="2"
                class="small text-center pr-1"
              >
                <div>โบนัส</div>
              </th>
              <th
                role="columnheader"
                scope="col"
                aria-colindex="3"
                class="small text-center pr-1"
              >
                <div>เดือน</div>
              </th>
            </tr>
          </thead>
          <tbody
            v-if="bonus.friendlist.length > 0"
            role="rowgroup"
          >
            <tr
              v-for="item in bonus.friendlist"
              :key="item.uid"
              role="row"
              class="b-table-empty-row"
            >
              <td>{{ item.fullname ? item.fullname: '-' }}</td>
              <td class="text-center">
                {{ item.winlose ? item.winlose: '-' }}
              </td>
              <td class="text-center">
                <p
                  v-if="item.winlose > 0"
                  class="mt-1"
                >
                  0
                </p>
                <p
                  v-else-if="item.winlose < 0"
                  class="mt-1"
                >
                  {{ (item.winlose * -1 )* 0.05 }}
                </p>
              </td>
              <td class="text-center">
                {{ bonus.month ? bonus.month : '-' }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              role="row"
              class="b-table-empty-row"
            >
              <td
                colspan="5"
                role="cell"
              >
                <div
                  role="alert"
                  aria-live="polite"
                >
                  <div class="text-color text-center pt-2 fs-14">
                    ไม่พบข้อมูล
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </b-overlay>
</template>

<script>
// import VueQrcode from 'vue-qrcode'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BOverlay,
  BIconController,

} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BIconController,
    // VueQrcode,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

  },
  props: {
    // eslint-disable-next-line
    UserProfile: Object,
  },
  data() {
    return {
      data: [],
      show: true,
      message: '',
      balance: '',
      amount: '',
      friendData: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      User: '',
      bonus: '',
      prolist: null,
    }
  },
  mounted() {
    this.GetBonus()
    this.getProlist()
  },

  methods: {
    ReceivePro10() {
      this.show = true
      this.$http
        .get(`/promotion/getbetflixpro/${10}`)
        .then(response => {
          this.show = false
          this.Success(response.data.message)
        })
        .catch(
          error => this.SwalError(error.response.data.message),
          (this.show = false),
        )
    },
    getProlist() {
      this.show = true
      this.$http
        .get('/promotion/prolist')
        .then(response => {
          this.show = false
          const index = response.data.findIndex(item => item.id === 10)
          this.prolist = response.data[index]
        })
        .catch(
          error => this.SwalError(error.response.data.message),
          (this.show = false),
        )
    },
    GetBonus() {
      this.$http
        .get('/BonusFriend/list')
        .then(response => {
          this.bonus = response.data
        })
        .catch(error => console.log(error))
    },
    submit(friend) {
      this.show = true
      const formData = {
        fid: friend.id,
      }
      this.$http
        .post('/promotion/depositbonus', formData)
        .then(response => {
          this.show = false
          this.Success(response.data.message)
          this.amount = ''
          this.getUser(this.userData)
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: 'SUCCESS',
        text: mes,
        showConfirmButton: true,
        timer: 4500,
        background: '#202124',
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<span>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },

}
</script>

<style scoped>
  .invite_color {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    color: #fff;
    background: linear-gradient(180deg, #626166, #3d3a3d) !important;
    border-radius: 10px;
  }
  .container-sm {
    max-width: 500px;
  }
.header-dark {
  background: linear-gradient(180deg, #626166, #3d3a3d) !important;
  padding: 10px;
  border-radius: 10px;
}
.card-list {
  margin: 0 5px;
  background: #2e2e2e;
  box-shadow: 0 4px 10px rgb(0 0 0 / 12%);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.card {
  border-radius: 14px;
  border: 1px solid rgb(51, 51, 51);
  background: #232323 !important;
  color: rgb(255, 255, 255) !important;
}
.text-yellow {
  color: #ffc107 !important;
}
.bg-balance {
  background-image: url(https://www.ltobet.com/assets/img/bg-banner-home.svg) !important;
  background-repeat: repeat !important;
  background-size: 100% auto !important;
}
.btn-withdraw {
  height: 36px;
  background-color: #ffffff27;
  color: #fff !important;
  border: 1px solid #fff;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.btn-depo {
  height: 36px;
  background: #fff;
  border: none;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.theme-ltobet .book_bank_content .bank-icon .media-icon {
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  border-radius: 4px;
  position: relative;
}
.btn-orange {
  background-image: linear-gradient(103deg, #0c2574 1%, #341082);
  width: 100%;
  border-radius: 10px !important;
  color: #ffffff !important;
}
.box-ac-items {
  padding: 10px;
  border-radius: 5px;
  border: #000 1px solid;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.highlight {
  color: rgb(255, 208, 0);
  font-size: large;
}
.btns {
  margin-top: 10px;
  padding: 5px 15px;
  color: #fff;
  background: linear-gradient(180deg, #626166, #3d3a3d) !important;
  border-radius: 10px;
}
</style>
